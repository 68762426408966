import { Box, InputLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const FORM_FOOTER_HEIGHT = '56px';

export const AnalysisFormWrapper = styled('form')`
  overflow-anchor: none;
  display: grid;
  grid-template-rows: 1fr 56px;
  height: 100%;
`;

export const FormContainer = styled(Box)`
  overflow-y: auto;
  max-height: calc(100vh - 212px);
`;

type FormSectionProps = {
  variant?: 'header' | 'regular';
};
export const FormSection = styled('fieldset')<FormSectionProps>(
  ({ variant = 'regular' }) => `
  display: flex;
  flex-flow: column wrap;
  gap: 10px;
  margin-bottom: 20px;
  padding: ${variant === 'header' ? '20px 0' : '0 0 20px'};
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 3px 6px #1919190f;
  `,
);

type FormRowProps = {
  variant?: 'primary' | 'regular';
};

export const FormRow = styled(Box)<FormRowProps>(({ variant }) => ({
  display: 'flex',
  height: 'auto',
  padding: '0 20px',
  gap: '20px',
}));

export const FullWidthInputStyle = {
  width: '100%',
  maxWidth: '300px',
};

export const InputLabelStyled = styled(InputLabel)`
  font-weight: bold;
  width: 50%;
`;

export const FormSectionHeader = styled(Typography)`
  margin: 0 0 12px;
  padding: 12px;
  font-size: 14px;
  color: var(--black);
  font-weight: bold;
  border-bottom: 1px solid var(--soft-blue);
`;

export const FormFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 1px solid #708fc51f;
`;
