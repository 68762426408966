import { FormDropDownOption } from '_types/Form';

export const DEFAULT_SELECT = 'Select';

export const RELATIVE_EXPENDITURE_OPTIONS: FormDropDownOption[] = [
  { value: 1, label: 'much less' },
  { value: 2, label: 'less' },
  { value: 3, label: 'about the same' },
  { value: 4, label: 'more' },
  { value: 5, label: 'much more' },
];

export const NUMBER_OF_COMPETITORS: FormDropDownOption[] = [
  { value: 1, label: '0' },
  { value: 2, label: '1' },
  { value: 3, label: '2 to 5' },
  { value: 4, label: '6 to 15' },
  { value: 5, label: '16 to 29' },
  { value: 6, label: '30 or more' },
];

export const NUMBER_OF_CUSTOMERS: FormDropDownOption[] = [
  { value: 1, label: '< 20' },
  { value: 2, label: '20 to 99' },
  { value: 3, label: '100 - 999' },
  { value: 4, label: '1,000 - 9,999' },
  { value: 5, label: '10,000 - 99,999' },
  { value: 6, label: '100,000 - 999,999' },
  { value: 7, label: '1,000,000 - 9,999,999' },
  { value: 8, label: '10,000,000 - 24,999,999' },
  { value: 9, label: '> 25,000,000' },
];

export const FAMILIARITY: FormDropDownOption[] = [
  { value: 1, label: 'unfamiliar' },
  { value: 2, label: 'somewhat familiar' },
  { value: 3, label: 'very familiar' },
];

export const SALES_PERCENTAGE: FormDropDownOption[] = [
  { value: 1, label: '< 25%' },
  { value: 2, label: '25 - 49%' },
  { value: 3, label: '50 - 75%' },
  { value: 4, label: '> 75%' },
];
