import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import sidebarBackground from '@assets/images/sidebar-background.png';

export const AppSidebarStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: url(${sidebarBackground}) no-repeat bottom,
    linear-gradient(174deg, var(--sidebar-gradient-start) 0%, var(--sidebar-gradient-end) 100%) 0 0 no-repeat
      padding-box;
`;

export const AppSidebarHeaderStyled = styled('div')`
  display: flex;
  justify-content: center;
  width: var(--sidebar-width);
  height: var(--page-heading-height);
  padding: 10px 8px 0;
  border-bottom: 1px solid var(--soft-blue);
`;

export const NavLinkStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 45px;
  color: #708fc5;
  font-size: 16px;
  text-decoration: none;
`;
