import windowBackground from '@assets/images/window-background.png';
import { Box, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentContainerStyled = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
`;

export const ContentBoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 193px 50px 30px;
  width: 80%;
  height: 720px;
  font-size: 14px;
  border-radius: 8px;
  background: url(${windowBackground}) no-repeat top, #fff;
  box-shadow: 0 3px 6px #1919190f;
  position: relative;

  > * {
    align-self: stretch;
  }
`;

export const HomepageLogoStyled = styled(Box)`
  position: absolute;
  top: 48px;
  left: 56px;
`;

export const HomepageHeaderStyled = styled(Box)`
  display: flex;
  margin-top: 10px;
`;

export const HomepageInnerContentStyled = styled(Box)`
  margin: 20px 0;
`;

export const HomepageContentFooterStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 20px;
  margin-top: auto;
`;

export const ListStyled = styled(List)`
  list-style-type: none;
  list-style-position: inside;
  margin-bottom: 16px;
  padding: 0;
`;

export const ListItemStyled = styled(ListItem)`
  display: list-item;
  padding: 0;
  position: relative;

  &::before {
    content: '• ';
    color: #e93b55;
    font-weight: bold;
    font-size: 16px;
    padding-right: 10px;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const IndicatorContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const StatusListStyled = styled('ul')`
  display: flex;
  gap: 14px;
  margin: 0;
`;

type StatusListDotStyledProps = {
  isActive: boolean;
};

export const StatusListDotStyled = styled('span')<StatusListDotStyledProps>(
  ({ isActive }) => `
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${isActive ? '#0047ab' : '#d9dfe9'};
`,
);

export const TitleStyled = styled(Typography)`
  margin-bottom: 20px;
  font-weight: bold;
  color: var(--cobalt);
`;

export const SliderRowStyled = styled('fieldset')`
  display: grid;
  grid-template-columns: 1fr 200px 80px;
  grid-column-gap: 20px;
  height: auto;
`;

export const SliderRowSubmitStyled = styled('fieldset')`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 20px;
  box-shadow: none;
`;
