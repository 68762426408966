import { HelpOutline } from '@mui/icons-material';
import { Box, Grid, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

export const ChartsContainerStyled = styled(Grid)`
  display: flex;
  flex-direction: row;
  z-index: 4;
  margin-bottom: 0;
  padding: 0;
  border-radius: 4px;
  background-color: var(--white);
`;

export const MultipleChartContainerStyled = styled(Box)`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
`;

export const ChartHeaderStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--soft-blue);
  padding: 5px 10px 5px 20px;
`;

interface MenuItemStyledProps {
  hasSubheadings?: boolean;
}

export const MenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'hasSubheadings',
})<MenuItemStyledProps>(({ hasSubheadings }) => ({
  marginLeft: hasSubheadings ? '20px' : 0,
}));

export const ChartSelectStyles = {
  '& legend': { display: 'none' },
  '& fieldset': { top: 0, border: 'none' },
  '#chart-type-select': { paddingLeft: 0 },
  backgroundColor: '#FFFFFF',
  fontWeight: 'bold',
};

export const ActionIcons = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#F1F5F8 0% 0% no-repeat padding-box',
    boxShadow: '6px 6px 12px #211F3B1F',
    color: '#211F3B',
    borderRadius: '2px',
    padding: '18px 17px 20px 20px',
    fontSize: '12px',
  },
}));

export const HelpIcon = styled(HelpOutline)`
  color: var(--soft-blue);
  font-size: 1.2rem;
  margin-bottom: 16px;
`;

export const MultipleChartAnalysisBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  margin: 20px 30px 0 20px;
  padding-bottom: 15px;
  width: 100%;
  aspect-ratio: 4/3;
`;

export const MultipleChartBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-items: end;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--white);
  width: 80%;
  margin: 10px 5px 0 0;

  '& #multipleChart': {
    overflow: hidden;
  }
`;

export const MultipleChartTypography = styled(Typography)`
  margin-top: 10px;
  text-align: center;
  font-size: 10px;
  color: #747474;
  background-color: var(--white);
`;

export const DescriptionBoxContainerStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 24px;
`;

export const DescriptionBoxStyled = styled(Box)`
  margin: 80px 24px 24px;
  padding: 24px;
  border-radius: 2px;
  background-color: var(--soft-grey);
  box-shadow: 0 3px 6px #3030300f;
`;

export const DescriptionBoxHeaderStyled = styled(Typography)`
  margin: 36px 0 12px;
  font-size: 14px;
  font-weight: bold;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const DescriptionBoxDefinitionStyled = styled(Box)`
  font-size: 14px;
`;
