import { createTheme, styled } from '@mui/material/styles';

import { STYLES_DATA } from '@constants/stylesData';

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      autoCompleteFieldBtnSize: number;
      generalFontSize: number;
    };
  }
  interface ThemeOptions {
    sizes?: {
      autoCompleteFieldBtnSize?: number;
      generalFontSize?: number;
    };
  }
}

export const theme = createTheme({
  sizes: {
    autoCompleteFieldBtnSize: 30,
    generalFontSize: STYLES_DATA.generalFontSize,
  },
  palette: {
    primary: {
      main: '#0047ab',
    },
    error: {
      main: '#e93b55',
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignSelf: 'center',
          alignItems: 'flex-start',
          fontSize: '14px',
          color: '#708fc5',
          textAlign: 'left',
          transform: 'none',
          whiteSpace: 'break-spaces',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          fontSize: '12px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          alignSelf: 'center',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: `${STYLES_DATA.generalFontSize}px`,
          borderRadius: '4px',
          textTransform: 'capitalize',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: `${STYLES_DATA.generalFontSize}px`,
          color: '#1e1e1e',
        },
        notchedOutline: {
          border: '1px solid #708fc5',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: `${STYLES_DATA.generalFontSize}px`,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          margin: '4px 0 0 0',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: `${STYLES_DATA.generalFontSize}px`,
          color: '#1e1e1e',
        },
      },
    },
  },
});

export const AppPageWrapperStyled = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 100vh;
`;
