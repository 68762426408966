import { camelCase, startCase } from 'lodash';

export const toPascalCase = (string: any): string => {
  return startCase(
    camelCase(
      string
        .toString()
        .replace(/[^a-zA-Z0-9]/g, ' ')
        .replace(/ +/g, ' '),
    ),
  ).replace(/ /g, '');
};
