import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextFieldStyled = styled(TextField)({
  width: '28%',
  maxWidth: '125px',
  '& legend': { display: 'none' },
  '& fieldset': { top: 0, borderColor: '#708fc5' },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff',
    height: '37px',
  },
  '& .MuiInputLabel-shrink': {
    display: 'none',
  },
  '& .MuiInputLabel-root': {
    color: '#747474 !important',
  },
  '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl.MuiAutocomplete-inputRoot': {
    fontSize: 14,
  },
});
