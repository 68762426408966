import {
  CreateStartupShareAnalysisPayload,
  StandardFindingsChartData,
  StandardFindingsPayload,
  StartupShareAnalysisData,
} from '_types/Business';

import { httpService } from './http.service';

class BusinessService {
  async createStartupShareAnalysis(payload: CreateStartupShareAnalysisPayload): Promise<StartupShareAnalysisData> {
    return httpService.create<CreateStartupShareAnalysisPayload>('/data', payload);
  }
  async getStandardFindingsChart(payload: StandardFindingsPayload): Promise<StandardFindingsChartData> {
    return httpService.create<StandardFindingsPayload>('/stdchart', payload);
  }
}

export const businessService = new BusinessService();
