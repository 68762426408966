import '@styles/main.scss';

import { MsalProvider } from '@azure/msal-react';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from '@store/configureStore';

import { AppBarLoader } from '@components/AppBar/app-bar-loader';

import { App } from './app/';
import { reportWebVitals } from './reportWebVitals';

const appBarLoader = new AppBarLoader();

const timeout = setTimeout(async () => {
  const msalInstance = await appBarLoader.getMsalInstance();

  const persistor = persistStore(store);

  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <StrictMode>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <PersistGate persistor={persistor} loading={null}>
              <App />
            </PersistGate>
          </BrowserRouter>
        </MsalProvider>
      </Provider>
    </StrictMode>,
  );

  clearTimeout(timeout);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
