export class LogService {
  getStackTrace() {
    const obj = {
      stack: null,
    };

    Error.captureStackTrace(obj, this.getStackTrace);

    return obj.stack;
  }

  error(error: any) {
    // eslint-disable-next-line no-console
    console.error({ error, stackTrace: this.getStackTrace() });
  }

  warn(warn: any) {
    // eslint-disable-next-line no-console
    console.warn({ warn, stackTrace: this.getStackTrace() });
  }

  log(msg: any) {
    // eslint-disable-next-line no-console
    console.log({ msg, stackTrace: this.getStackTrace() });
  }
}
