import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AppPageContentStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  background-color: var(--soft-grey);
`;

export const AppPageHeaderStyled = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: var(--page-heading-height);

  background-color: var(--lighter-blue);
`;

export const AppPageHeaderTitleStyled = styled(Typography)`
  margin: 0;
  padding-top: 7px;
  font-size: 27px;
  line-height: 34px;
  font-weight: bold;
  color: var(--cobalt);
`;

export const AppPageInnerWrapperStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  flex: 1;
  overflow-y: auto;
  padding: 20px 20px 60px; // bottom padding is 40px more because of the height of the AppBar
`;
