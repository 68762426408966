import { CircularProgress } from '@mui/material';

import { SpinnerWrapperStyled } from '@components/Spinner/Spiner.styles';

export const Spinner = () => {
  return (
    <SpinnerWrapperStyled>
      <CircularProgress />
    </SpinnerWrapperStyled>
  );
};
