import { Route, Routes } from 'react-router-dom';

import { Layout } from '@components/Layout';
import { SIDEBAR_ITEMS } from '@constants/sidebar';

export const AppRouter = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {SIDEBAR_ITEMS.map((item) => (
          <Route key={item.key} path={item.url} element={<item.element />} />
        ))}
      </Route>
    </Routes>
  );
};
