import { useAppSelector } from '@hooks/store';

import deVocabulary from '@assets/locales/de/translations.json';
import engVocabulary from '@assets/locales/eng/translations.json';

interface Vocabulary {
  [key: string]: any;
}

export const useTranslator = (phraseKey?: string | undefined, phrasesKeysObjects?: string[] | undefined): any => {
  const lang = useAppSelector((store) => store.language.currentLanguage);

  let phraseTranslated = '';

  const findTranslation = (
    phraseKey: string | undefined,
    phrasesKeysObjectsList: string[] | undefined,
    vocabulary: Vocabulary,
  ) => {
    if (phrasesKeysObjectsList && phrasesKeysObjectsList.length > 0) {
      //return one or more objects with translation for keys in phrasesKeysObjectsList from json vocabulary file
      return phrasesKeysObjectsList.map((name) => vocabulary[name]);
    }

    if (phraseKey && phraseKey.indexOf('.') > 0) {
      const objKeys = phraseKey.split('.');

      //return translation for phraseKey if phraseKey is described as a field of object in json vocabulary file
      return vocabulary[objKeys[0]][objKeys[1]];
    }

    //return translation if phraseKey is stored as an string in json vocabulary file
    return phraseKey && vocabulary[phraseKey];
  };

  if (phraseKey !== undefined || phrasesKeysObjects !== undefined) {
    switch (lang) {
      case 'ENG':
        phraseTranslated = findTranslation(phraseKey, phrasesKeysObjects, engVocabulary);

        break;
      case 'DE':
        phraseTranslated = findTranslation(phraseKey, phrasesKeysObjects, deVocabulary);

        break;
      default:
        phraseTranslated = findTranslation(phraseKey, phrasesKeysObjects, engVocabulary);
    }
  }

  return phraseTranslated;
};
