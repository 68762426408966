import { Box } from '@mui/material';
import Plotly from 'plotly.js-dist-min';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import createPlotlyComponent from 'react-plotly.js/factory';

import { AnalysisDescription } from '@components/AnalysisDescription';
import { startupShareAnalysisPayload } from '@constants/chart';
import { AnalysisForm } from '@features/Analysis/AnalysisForm.component';
import { objectMap } from '@helpers/object.helper';
import { businessService } from '@services/api/business.service';

import { CreateStartupShareAnalysisPayload, StartupShareAnalysisData } from '_types/Business';
import { StartupShareAnalysisPayload } from '_types/Form';

import { getAnalysisChartConfig, getAnalysisChartData, initialCurvePoints } from './Analysis.helper';
import { AnalysisContentWrapperStyled } from './Analysis.styles';

const Plot = createPlotlyComponent(Plotly);

export const Analysis = () => {
  const [chartData, setChartData] = useState<Partial<StartupShareAnalysisData>>({});

  const analysisChartConfig = useMemo(() => getAnalysisChartConfig(chartData), [chartData]);

  const analysisChartData = useMemo(() => {
    return getAnalysisChartData(chartData);
  }, [chartData]);

  const methods = useForm({
    defaultValues: startupShareAnalysisPayload,
  });

  const { handleSubmit, reset } = methods;

  const callAPI = async (data: StartupShareAnalysisPayload) => {
    const mappedData = objectMap(data, (value: any) => (value === null ? -999 : value));

    const analysisData = await businessService.createStartupShareAnalysis(
      mappedData as CreateStartupShareAnalysisPayload,
    );

    setChartData(analysisData);
  };

  const resetChart = () => {
    const analysisChartData: Partial<StartupShareAnalysisData> = { curvePoints: initialCurvePoints };

    setChartData(analysisChartData);
    reset();
  };

  return (
    <>
      <AnalysisContentWrapperStyled>
        <FormProvider {...methods}>
          <AnalysisForm onSubmit={handleSubmit(callAPI)} onReset={resetChart} />
        </FormProvider>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: '1fr 120px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0 3px 6px #1919190f',
            }}
          >
            <Box
              sx={{
                width: '90%',
              }}
            >
              <Plot
                data={analysisChartData}
                layout={analysisChartConfig}
                divId="analysisChart"
                config={{ responsive: true, displayModeBar: false }}
                useResizeHandler
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
          </Box>
          <AnalysisDescription analysisData={chartData} />
        </Box>
      </AnalysisContentWrapperStyled>
    </>
  );
};
