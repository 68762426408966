import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type LanguageState = {
  currentLanguage: string;
};

const initialState: LanguageState = {
  currentLanguage: 'EN',
};

const languageSlice = createSlice({
  name: 'language',
  initialState,

  reducers: {
    languageSet: (state, { payload }: PayloadAction<string>) => {
      state.currentLanguage = payload;
    },
  },
});

export const { languageSet } = languageSlice.actions;

export const languageReducer = languageSlice.reducer;
