import { Grid, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { MultipleChart } from '@components/MultipleCharts';
import { Spinner } from '@components/Spinner';
import { useTranslator } from '@hooks/translator';
import { businessService } from '@services/api/business.service';

import { StandardFindingsChartData } from '_types/Business';

import { ChartTitle } from './Research.styles';

const DECIMAL_POINTS = 1;

export const Research = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [selectedChart, setSelectedChart] = useState<any>(572);
  const [standardFindingsData, setStandardFindingsData] = useState<StandardFindingsChartData>({});

  const chartTitle = useTranslator('research.title');

  useEffect(() => {
    const payload = {
      vbl: selectedChart,
      lang: 44,
    };

    businessService.getStandardFindingsChart(payload).then((response) => {
      response.sharePoints = response.sharePoints?.map((point) => {
        return Number.parseFloat(point.toFixed(DECIMAL_POINTS));
      });

      setStandardFindingsData(response);
      setIsReady(true);
    });
  }, [selectedChart]);

  const handleChartSelect = useCallback(
    (event: SelectChangeEvent) => {
      setSelectedChart(event.target.value);
    },
    [selectedChart],
  );

  return (
    <>
      {isReady ? (
        <>
          <Grid item xs={12}>
            <ChartTitle>{chartTitle}</ChartTitle>
            <MultipleChart analysisData={standardFindingsData} chartId={selectedChart} onSelect={handleChartSelect} />
          </Grid>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};
