import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { AppBarLoader } from '@components/AppBar/app-bar-loader';
import { LanguageLocale } from '@components/AppBar/app-bar-state';
import AppBarStateObservable from '@components/AppBar/AppBarStateObservable';
import { getBrowserLanguage } from '@helpers/getBrowserLanguage';

import { rootReducer } from './rootReducer';
import { languageSet } from './slices/language';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',

  middleware: [thunk, logger],
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export { store };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const appBarStateObservable = new AppBarStateObservable();
const appBarLoader = new AppBarLoader();

appBarStateObservable.attach((appBarState) => {
  if (appBarState.loggedInState === 'logging-in') {
    sessionStorage.removeItem('persist:root');
  }

  const lang = store.getState().language.currentLanguage;
  const savedLang = localStorage.getItem('lang');

  if (!lang && !savedLang) {
    //set initial lang by geolocation
    appBarLoader.setLanguage(LanguageLocale.English);

    return getUserLocation();
  }

  if (!lang && savedLang) {
    //set initial lang by what was saved earlier
    const lang = savedLang == 'DE' ? LanguageLocale.German : LanguageLocale.English;

    appBarLoader.setLanguage(lang);
    store.dispatch(languageSet(savedLang));

    return;
  }

  //when user switch lang manually
  if (lang !== appBarState.language.name) {
    store.dispatch(languageSet(appBarState.language.name));
    localStorage.setItem('lang', appBarState.language.name);
  }
});

const getUserLocation = async () => {
  axios
    .get(process.env.IP_API || 'https://ipapi.co/json/')
    .then((response) => {
      successCallback(response.data);
    })
    .catch((error) => {
      console.error(error);
      failureCallback();
    });
};

const successCallback = ({ country }: { country: string }) => {
  let appLoaderLang;
  let storeLang;

  switch (country) {
    case 'GB':
      appLoaderLang = LanguageLocale.English;
      storeLang = 'ENG';
      break;
    case 'DE':
      appLoaderLang = LanguageLocale.German;
      storeLang = 'DE';
      break;
    default:
      appLoaderLang = LanguageLocale.English;
      storeLang = 'ENG';
      break;
  }

  appBarLoader.setLanguage(appLoaderLang);
  store.dispatch(languageSet(storeLang));
  localStorage.setItem('lang', storeLang);
};

const failureCallback = () => {
  const browserLang = getBrowserLanguage();

  //set initial lang by browser settings if there is not geolocation
  if (browserLang) {
    const appLoaderLang = browserLang === 'de' ? LanguageLocale.German : LanguageLocale.English;

    const storeLang = browserLang === 'de' ? 'DE' : 'ENG';

    appBarLoader.setLanguage(appLoaderLang);
    store.dispatch(languageSet(storeLang));
    localStorage.setItem('lang', storeLang);

    return;
  }

  //set initial lang english by default finally
  store.dispatch(languageSet('ENG'));
  localStorage.setItem('lang', 'ENG');

  return;
};
