import './startupShare.css';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';

import { Sidebar } from '@components/Sidebar';

import { AppPageWrapperStyled, theme } from './App.styles';
import { AppRouter } from './AppRouter';

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthenticatedTemplate>
        <AppPageWrapperStyled>
          <Sidebar />
          <AppRouter />
        </AppPageWrapperStyled>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
    </ThemeProvider>
  );
};
