import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChartIconWrapper = styled(Box)(
  ({ color }) => `
    margin: 0 6px 0 0;
    padding: 0;
    width: 24px;
    height: 24px;
    justify-self: center;
    align-self: center;
    color: ${color ? color : '#0047AB'};
    cursor: pointer;
  `,
);

export const ModalStyles = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '8px',
  boxShadow: 24,
  p: 3,
  zIndex: 999999,
};
