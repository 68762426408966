import { InputAdornment, InputLabel, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { startupShareAnalysisPayload } from '@constants/chart';
import { useTranslator } from '@hooks/translator';

import LogoIcon from '@assets/images/logo-icon.svg';

import {
  ButtonsContainer,
  ContentBoxStyled,
  ContentContainerStyled,
  HomepageContentFooterStyled,
  HomepageHeaderStyled,
  HomepageInnerContentStyled,
  HomepageLogoStyled,
  IndicatorContainer,
  ListItemStyled,
  ListStyled,
  SliderRowStyled,
  SliderRowSubmitStyled,
  StatusListDotStyled,
  StatusListStyled,
  TitleStyled,
} from './Homepage.styles';

export const HomePage = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const methods = useForm({
    defaultValues: startupShareAnalysisPayload,
    mode: 'all',
  });
  const navigate = useNavigate();

  const { control, watch, setValue } = methods;

  const targetMarketShare = watch('targetMarketShare');

  const [wizardHeaders] = useTranslator(undefined, ['homepage_wizard']);

  const handleSliderChange = (field: any) => (value: number | number[]) => {
    setValue(field, value);
    setTargetMarketShare(value);
  };

  const handleInputChange = (field: any) => (value: number | string | Array<number | string>) => {
    setValue(field, value);
    setTargetMarketShare(value);
  };

  const setTargetMarketShare = (value: any) => {
    sessionStorage.setItem('targetMarketShare', value);
  };

  const goToAnalysis = () => navigate('/analysis');

  useEffect(() => {
    sessionStorage.removeItem('targetMarketShare');
  }, []);

  const handleNext = () => {
    setCurrentStep((state) => (state < 3 ? state + 1 : state));
  };

  const handleBack = () => {
    setCurrentStep((state) => (state > 1 ? state - 1 : state));
  };

  const handleSkip = () => {
    setCurrentStep(3);
  };

  return (
    <ContentContainerStyled>
      <ContentBoxStyled>
        <HomepageLogoStyled>
          <img src={LogoIcon} alt="PIMS logo" />
        </HomepageLogoStyled>
        <>
          {currentStep === 1 && (
            <>
              <HomepageHeaderStyled>
                <TitleStyled variant="h5">
                  <span dangerouslySetInnerHTML={{ __html: wizardHeaders['header_1'] }} />
                </TitleStyled>
              </HomepageHeaderStyled>
              <HomepageInnerContentStyled>
                <div>
                  <p>
                    Gaining a strong market share should be the main aim of a start-up business, this enables economies
                    of scale and should lead to long-term profitability.
                  </p>

                  <p>
                    Most start-up businesses do not make profits for several years and those that do make money in the
                    first 2 years have a tendency to perform worse at maturity as they can usually only do this by
                    skimping on areas such as investment, marketing and second-wave innovation.
                  </p>

                  <p>
                    Profitability should therefore be a long term goal rather than the initial target for start-up
                    businesses.
                  </p>
                </div>
              </HomepageInnerContentStyled>
            </>
          )}

          {currentStep === 2 && (
            <>
              <HomepageHeaderStyled>
                <TitleStyled variant="h5">
                  <span dangerouslySetInnerHTML={{ __html: wizardHeaders['header_2'] }} />
                </TitleStyled>
              </HomepageHeaderStyled>
              <HomepageInnerContentStyled>
                <div>
                  <p>
                    This model provides an indication of the likelihood of a business achieving a given market share by
                    year 4 based on:
                  </p>

                  {/*<ul style={{ listStyleType: 'none', listStylePosition: 'inside', marginBottom: 16 }}>*/}
                  <ListStyled>
                    <ListItemStyled>
                      Its initial tactics in terms of marketing and other expenditures, and your perceived relative
                      quality position.
                    </ListItemStyled>
                    <ListItemStyled>The existing market structure.</ListItemStyled>
                    <ListItemStyled>Your familiarity with the market.</ListItemStyled>
                  </ListStyled>

                  <p>
                    The model runs a series of probability models to assess the likelihood of achieving a particular
                    target market share after 4 years based on your initial tactics. Additionally, it computes the share
                    with a 50% probability of success (the likely share) for comparison.
                  </p>
                  <p>
                    It makes a number of assumptions, the main one being that you are a new entrant to an existing
                    market. It is an indicative model and should be treated as such – there are other factors which will
                    affect the success or failure of the business (barriers to entry, cashflow, etc.) which can be
                    addressed more fully in a PIMS® Startup Analysis.
                  </p>
                </div>
              </HomepageInnerContentStyled>
            </>
          )}

          {currentStep === 3 && (
            <>
              <HomepageHeaderStyled>
                <TitleStyled variant="h5">
                  <span dangerouslySetInnerHTML={{ __html: wizardHeaders['header_3'] }} />
                </TitleStyled>
              </HomepageHeaderStyled>
              <HomepageInnerContentStyled>
                <div>
                  <p>
                    On the next screen you will be asked to enter details of your initial plan and the market you are
                    entering. Please enter the market share you aim to achieve within 4 years (your target market share)
                    below in order to continue.
                  </p>

                  <form>
                    <SliderRowStyled>
                      <Tooltip
                        title="Enter the market share you expect to achieve in the 4th year after entry"
                        arrow
                        placement="right"
                      >
                        <InputLabel
                          shrink
                          sx={{
                            fontSize: '15px',
                          }}
                        >
                          <span>
                            <b>Target Market Share in Year 4:</b>
                          </span>
                        </InputLabel>
                      </Tooltip>
                      <Controller
                        name="targetMarketShare"
                        control={control}
                        render={({ field }) => (
                          <Slider
                            {...field}
                            sx={{ alignSelf: 'center' }}
                            size="small"
                            value={field.value}
                            aria-label="targetMarketShare"
                            valueLabelDisplay="auto"
                            min={0}
                            max={27}
                            step={1}
                            onChange={(event, value) => handleSliderChange(field.name)(value)}
                          />
                        )}
                      />
                      <TextField
                        value={targetMarketShare}
                        size="small"
                        aria-labelledby="targetMarketShare"
                        variant="outlined"
                        inputProps={{
                          min: 0,
                          max: 27,
                          step: 1,
                          style: { textAlign: 'right' },
                        }}
                        InputProps={{
                          type: 'number',
                          endAdornment: (
                            <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                              %
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => handleInputChange('targetMarketShare')(event.target.value)}
                      />
                    </SliderRowStyled>
                    <SliderRowSubmitStyled></SliderRowSubmitStyled>
                  </form>
                </div>
              </HomepageInnerContentStyled>
            </>
          )}
        </>

        <HomepageContentFooterStyled>
          <ButtonsContainer>
            {currentStep === 1 && (
              <Button variant="outlined" size="small" onClick={handleSkip}>
                Skip
              </Button>
            )}

            {currentStep > 1 && (
              <Button variant="outlined" size="small" onClick={handleBack}>
                Back
              </Button>
            )}

            {currentStep < 3 && (
              <Button
                variant="contained"
                size="small"
                onClick={handleNext}
                disabled={currentStep === 3}
                sx={{ width: '100px' }}
              >
                Continue
              </Button>
            )}

            {currentStep === 3 && (
              <Button variant="contained" size="small" onClick={goToAnalysis} disabled={!targetMarketShare}>
                Next
              </Button>
            )}
          </ButtonsContainer>
          <IndicatorContainer>
            <StatusListStyled>
              <li>
                <StatusListDotStyled isActive={currentStep === 1} />
              </li>
              <li>
                <StatusListDotStyled isActive={currentStep === 2} />
              </li>
              <li>
                <StatusListDotStyled isActive={currentStep === 3} />
              </li>
            </StatusListStyled>
          </IndicatorContainer>
        </HomepageContentFooterStyled>
      </ContentBoxStyled>
    </ContentContainerStyled>
  );
};
