export const getBrowserLanguage = () => {
  let browserLang: string;

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    browserLang = navigator.languages[0]?.substr(0, 2);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
  } else if (navigator?.userLanguage as unknown) {
    // IE only
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    browserLang = navigator?.userLanguage?.substr(0, 2);
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    browserLang = navigator.language?.substr(0, 2);
  }

  return browserLang;
};
