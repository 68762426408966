import { Outlet } from 'react-router-dom';

import { useTranslator } from '@hooks/translator';

import {
  AppPageContentStyled,
  AppPageHeaderStyled,
  AppPageHeaderTitleStyled,
  AppPageInnerWrapperStyled,
} from './Layout.styles';

export const Layout = () => {
  return (
    <AppPageContentStyled>
      <AppPageHeaderStyled>
        <AppPageHeaderTitleStyled variant="h1">{useTranslator('page_header')}</AppPageHeaderTitleStyled>
      </AppPageHeaderStyled>
      <AppPageInnerWrapperStyled>
        <Outlet />
      </AppPageInnerWrapperStyled>
    </AppPageContentStyled>
  );
};
