export const objectMap = <T>(object: Record<string, T>, mapFn: (value: T) => T): Record<string, T> => {
  return Object.keys(object).reduce((result: Record<string, T>, key: string) => {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
};

export const setProperty = (object: Record<string, any>, path: string, value: any): object => {
  const [head, ...rest] = path.split('.');

  return {
    ...object,
    [head]: rest.length ? setProperty(object[head], rest.join('.'), value) : value,
  };
};
