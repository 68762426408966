import { DECIMAL_POINTS, ROUNDING_PRECISION } from '@constants/chart';
import { numberToFixed } from '@helpers/formatNumber';

import { StartupShareAnalysisData } from '_types/Business';
import { CurvePoint } from '_types/Form';

export const initialCurvePoints: CurvePoint[] = [
  { xdata: 3, ydata: 82.8 },
  { xdata: 5, ydata: 60.7 },
  { xdata: 7, ydata: 45.7 },
  { xdata: 9, ydata: 35.6 },
  { xdata: 11, ydata: 28.7 },
  { xdata: 13, ydata: 23.7 },
  { xdata: 15, ydata: 19.6 },
  { xdata: 17, ydata: 15.7 },
  { xdata: 19, ydata: 11.8 },
  { xdata: 21, ydata: 8.1 },
  { xdata: 23, ydata: 5 },
  { xdata: 25, ydata: 3.5 },
  { xdata: 27, ydata: 3.5 },
];

type TraceData = {
  x: number[];
  y: number[];
};

const mapCurvePoints = (curvePoints: CurvePoint[]): TraceData => {
  const newCurvePoints: TraceData = {
    x: [],
    y: [],
  };

  for (const point of curvePoints) {
    newCurvePoints.x.push(Number.parseFloat(point.xdata.toFixed(DECIMAL_POINTS)));
    newCurvePoints.y.push(Number.parseFloat(point.ydata.toFixed(DECIMAL_POINTS)));
  }

  return newCurvePoints;
};

export const getAnalysisChartConfig = (analysisData: Partial<StartupShareAnalysisData>) => {
  const { curvePoints, dataPoints } = analysisData;

  let mappedcurvePoints;

  if (dataPoints && curvePoints) {
    mappedcurvePoints = mapCurvePoints(curvePoints);
  } else {
    mappedcurvePoints = mapCurvePoints(initialCurvePoints);
  }

  const maxXPoint = Math.max(...mappedcurvePoints.x);
  const maxYPoint = Math.max(...mappedcurvePoints.y);

  const xAxisRange = [0, Math.ceil(maxXPoint / ROUNDING_PRECISION) * ROUNDING_PRECISION];

  return {
    autosize: true,
    font: {
      family: '"Roboto", helvetica, arial, sans-serif',
      size: 13,
    },
    title: {
      text: '<b>Probability of achieving share</b>',
    },
    legend: {
      orientation: 'h' as const,
      valign: 'top' as const,
      xanchor: 'center' as const,
      x: 0.5,
      y: -0.15,
      font: {
        size: 12,
        color: 'black',
      },
    },
    xaxis: {
      tickfont: {
        size: 11,
        color: 'black',
      },
      title: {
        text: '<b>Market Share (%)</b>',
        font: { size: 11, color: 'black' },
        standoff: 10,
      },
      range: xAxisRange,
    },
    yaxis: {
      tickfont: {
        size: 11,
        color: 'black',
      },
      ticks: 'outside' as const,
      tick0: 0,
      dtick: 10,
      tickcolor: 'white',
      ticklen: 10,
      showticksuffix: 'all' as const,
      ticksuffix: '%',
      title: {
        text: '<b>Probability (%)</b>',
        font: { size: 11, color: 'black' },
        standoff: 10,
      },
      range: [0, 100],
    },
    margin: {
      l: 80,
      r: 50,
      b: 60,
      t: 70,
      pad: 2,
    },
  };
};

export const getAnalysisChartData = (analysisData: Partial<StartupShareAnalysisData>) => {
  const { curvePoints, dataPoints, likelyProbability, likelyShare, targetProbability, targetShare } = analysisData;

  let traceData = [];
  let mappedcurvePoints;

  if (curvePoints && dataPoints) {
    mappedcurvePoints = mapCurvePoints(curvePoints);
  } else {
    mappedcurvePoints = mapCurvePoints(initialCurvePoints);
  }

  const marketShareTrace = {
    mode: 'lines' as const,
    name: 'Market Share',
    ...mappedcurvePoints,
    line: {
      color: '#284D99',
      width: 3,
    },
    hoverinfo: 'none' as const,
    showlegend: false,
    hovertemplate: '',
  };

  traceData = [marketShareTrace];

  if (curvePoints && dataPoints) {
    traceData = [
      ...traceData,
      // Target Market Share trace data
      {
        mode: 'lines' as const,
        name: 'Target Market Share',
        x: [0, numberToFixed(targetShare as number), numberToFixed(targetShare as number)],
        y: [numberToFixed(targetProbability as number), numberToFixed(targetProbability as number), 0],
        line: {
          color: '#000000',
          width: 2,
          dash: 'longdashdot',
        },
        hovertemplate: '<b>Probability: %{y}%</b><extra></extra>',
      },
      // Likely Market Share Trace data
      {
        mode: 'lines' as const,
        name: 'Likely Market Share',
        x: [0, numberToFixed(likelyShare as number), numberToFixed(likelyShare as number)],
        y: [numberToFixed(likelyProbability as number), numberToFixed(likelyProbability as number), 0],
        line: {
          color: '#FF0000',
          width: 2,
          dash: 'longdashdot',
        },
        hovertemplate: '<b>Share: %{x}%</b><extra></extra>',
      },
    ];
  }

  return traceData;
};
