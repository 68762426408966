import { AnalysisMultipleChartData } from '_types/Chart';

const getFakeXValuesForChartDisplaying = (length: number) => {
  switch (length) {
    case 2:
      return [5, 15, 25];
    case 3:
      return [5, 15, 25, 35];
    case 4:
      return [5, 15, 25, 35, 45];
    case 5:
      return [5, 15, 25, 35, 45, 55];
    case 6:
      return [5, 15, 25, 35, 45, 55, 65];
    case 7:
      return [5, 15, 25, 35, 45, 55, 65, 75];
    case 8:
      return [5, 15, 25, 35, 45, 55, 65, 75, 85];
    case 9:
      return [5, 15, 25, 35, 45, 55, 65, 75, 85, 95];
    default:
      return [10, 20];
  }
};

const getFakeXlabelsValuesForChartDisplaying = (length: number) => {
  switch (length) {
    case 2:
      return [10, 20];
    case 3:
      return [10, 20, 30];
    case 4:
      return [10, 20, 30, 40];
    case 5:
      return [10, 20, 30, 40, 50];
    case 6:
      return [10, 20, 30, 40, 50, 60];
    case 7:
      return [10, 20, 30, 40, 50, 60, 70];
    case 8:
      return [10, 20, 30, 40, 50, 60, 70, 80];
    case 9:
      return [10, 20, 30, 40, 50, 60, 70, 80, 90];
    default:
      return [10, 20];
  }
};

export const getMultipleChartConfig = (analysisData: AnalysisMultipleChartData) => {
  const { xlabels, chartType, title } = analysisData;

  if (!xlabels) {
    return;
  }

  const isRelativeCharts = chartType === 1;

  let tickLabels: number[];

  let tickText: string[];

  if (isRelativeCharts) {
    tickText = xlabels.map((label) => label.toString());
    tickLabels = xlabels.map((label) => +label);
  } else {
    tickText = xlabels.map((label) => label.toString());
    tickLabels = xlabels.map((_, index: number) => ++index);
  }
  const tickVals = getFakeXlabelsValuesForChartDisplaying(tickText.length);

  return {
    autosize: true,
    font: {
      family: '"Roboto", helvetica, arial, sans-serif',
      size: 12,
    },
    legend: {
      orientation: 'h' as const,
      valign: 'top' as const,
      xanchor: 'center' as const,
      x: 0.5,
      y: -0.15,
      font: {
        size: 14,
        color: 'black',
      },
    },
    yaxis: {
      tickfont: {
        size: 12,
        color: 'black',
      },
      ticks: 'outside' as const,
      tickcolor: 'white',
      ticklen: 5,
      showticksuffix: 'all' as const,
      ticksuffix: '%',
      title: {
        text: analysisData.yAxisTitle,
        font: { size: 12, color: 'black' },
        standoff: 15,
      },
      range: [0],
      rangemode: 'tozero' as const,
    },
    xaxis: {
      tickfont: {
        size: 12,
        color: 'black',
      },
      ...(tickLabels && {
        tickmode: 'array' as const,
        tickvals: tickVals,
        ticktext: tickText,
      }),
      title: {
        text: analysisData.xAxisTitle,
        font: { size: 12, color: 'black' },
      },
      range: [0, tickVals.slice(-1)[0] + 10],
      showgrid: false,
      zeroline: false,
    },
    title,
    margin: {
      l: 60,
      r: 0,
      t: 40,
      b: 40,
      pad: 2,
    },
  };
};

export const getMultipleChartData = (analysisData: AnalysisMultipleChartData) => {
  const { xlabels, chartType, sharePoints } = analysisData;

  if (!xlabels) {
    return;
  }

  let validXlabels: number[];
  let tickVals: number[];

  const isRelativeCharts = chartType === 1;

  if (isRelativeCharts) {
    validXlabels = xlabels?.map((label) => +label);
    tickVals = getFakeXValuesForChartDisplaying(validXlabels.length);
  } else {
    validXlabels = xlabels.map((_, index) => ++index);
    tickVals = getFakeXlabelsValuesForChartDisplaying(validXlabels.length + 1);
  }

  return [
    {
      type: 'scatter' as const,
      x: tickVals,
      y: sharePoints,
      name: 'Share %',
      line: { color: '#0047ab', width: 3 },
      hoverinfo: 'none' as const,
    },
  ];
};
