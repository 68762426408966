import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AnalysisContentWrapperStyled = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  overflow: hidden;
  position: relative;
  height: 100vh;
  max-height: 100vh;
`;

type AnalysisColumnProps = {
  columnType: 'form' | 'chart';
};

export const AnalysisColumnStyled = styled(Box)<AnalysisColumnProps>(
  ({ columnType }) => `
  grid-area: ${columnType};
`,
);
