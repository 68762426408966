import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import { languageReducer } from './slices/language';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const reducers = combineReducers({
  language: languageReducer,
});

export const rootReducer = persistReducer(persistConfig, reducers);
