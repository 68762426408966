import { FormDropDownOption } from '_types/Form';
import { DEFAULT_SELECT } from '@constants/form';
import { TextFieldStyled } from '@features/Analysis/Dropdown.styles';
import MenuItem from '@mui/material/MenuItem';
import { Controller, useFormContext } from 'react-hook-form';

interface DropdownProps {
  name: string;
  options: FormDropDownOption[] | null;
}
export const Dropdown = ({ name, options }: DropdownProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { onChange, value } }) => (
        <>
          <TextFieldStyled
            sx={{
              width: '28%%',
              maxWidth: 'none',
            }}
            InputLabelProps={{
              sx: {
                padding: 1,
                insetInline: '10px',
              },
            }}
            variant="outlined"
            select
            size="small"
            label={DEFAULT_SELECT}
            onChange={onChange}
          >
            {(options ?? []).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextFieldStyled>
        </>
      )}
    />
  );
};
