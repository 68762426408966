import { InputAdornment, TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import { Controller, useFormContext } from 'react-hook-form';

interface InputSliderProps {
  name: string;
  min: number;
  max: number;
  step?: number;
}

export const InputSlider = ({ name, min, max, step = 1 }: InputSliderProps) => {
  const { control, setValue } = useFormContext();

  if (name === 'targetMarketShare') setValue('targetMarketShare', sessionStorage.getItem('targetMarketShare') || 0);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <Slider
            value={value}
            size="small"
            onChange={onChange}
            min={min}
            max={max}
            step={step}
            sx={{ alignSelf: 'center', width: '35%' }}
            valueLabelDisplay="auto"
          />
          <TextField
            value={value}
            size="small"
            variant="outlined"
            onChange={onChange}
            onBlur={(el) => {
              if (value < min) {
                onChange(min);
              } else if (value > max) {
                onChange(max);
              }
            }}
            inputProps={{
              min,
              max,
              step,
              style: { textAlign: 'right' },
            }}
            InputProps={{
              type: 'number',
              endAdornment: (
                <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                  %
                </InputAdornment>
              ),
            }}
            sx={{ width: '15%' }}
          />
        </>
      )}
    />
  );
};
