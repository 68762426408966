import { RestartAltOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useForm } from 'react-hook-form';

import { startupShareAnalysisPayload } from '@constants/chart';
import {
  FAMILIARITY,
  NUMBER_OF_COMPETITORS,
  NUMBER_OF_CUSTOMERS,
  RELATIVE_EXPENDITURE_OPTIONS,
  SALES_PERCENTAGE,
} from '@constants/form';
import {
  AnalysisFormWrapper,
  FormContainer,
  FormFooter,
  FormRow,
  FormSection,
  FormSectionHeader,
  InputLabelStyled,
} from '@features/Analysis/AnalysisForm.styles';
import { ChartModalTrigger } from '@features/Analysis/ChartModalTrigger';
import { Dropdown } from '@features/Analysis/Dropdown.component';
import { InputSlider } from '@features/Analysis/InputSlider.component';
import { useTranslator } from '@hooks/translator';

interface AnalysisFormProps {
  onSubmit: any;
  onReset: () => void;
}

export const AnalysisForm = ({ onSubmit, onReset }: AnalysisFormProps) => {
  const { setValue } = useForm({
    defaultValues: startupShareAnalysisPayload,
  });

  const [formElements, analysisFormData] = useTranslator(undefined, ['form_elements', 'analysis_form']);

  return (
    <AnalysisFormWrapper onSubmit={onSubmit}>
      <FormContainer>
        <FormSection variant="header">
          <FormRow variant="primary">
            <Tooltip title={analysisFormData['target_market_share_tooltip']} arrow placement="right">
              <InputLabelStyled
                sx={{
                  fontSize: '15px',
                }}
              >
                {analysisFormData['target_market_share_label']}:
              </InputLabelStyled>
            </Tooltip>
            <InputSlider name="targetMarketShare" min={0} max={27} />
          </FormRow>
        </FormSection>

        <FormSection>
          <FormSectionHeader variant="h2">{analysisFormData['startup_profile']}</FormSectionHeader>

          <FormRow>
            <ChartModalTrigger chartId={572} />
            <Tooltip title={analysisFormData['proposed_total_marketing_spend_tooltip']} arrow placement="right">
              <InputLabelStyled>
                <span>{analysisFormData['proposed_total_marketing_spend_label']}:</span>
              </InputLabelStyled>
            </Tooltip>
            <InputSlider name="proposedTotalMarketingSpend" min={0} max={20} step={0.1} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={467} />
            <Tooltip title={analysisFormData['proposed_sales_force_expenditure_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['proposed_sales_force_expenditure_label']}:</InputLabelStyled>
            </Tooltip>
            <InputSlider name="proposedSalesForceExpenditure" min={0} max={8} step={0.1} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={575} />
            <Tooltip title={analysisFormData['production_capacity_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['production_capacity_label']}:</InputLabelStyled>
            </Tooltip>
            <InputSlider name="productionCapacity" min={0} max={100} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={573} />
            <Tooltip title={analysisFormData['relative_product_quality_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['relative_product_quality_label']}:</InputLabelStyled>
            </Tooltip>
            <InputSlider name="relativeProductQuality" min={-50} max={50} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={520} />
            <Tooltip title={analysisFormData['relative_salesforce_expenditure_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['relative_salesforce_expenditure_label']}:</InputLabelStyled>
            </Tooltip>
            <Dropdown name="relativeSalesforceExpenditure" options={RELATIVE_EXPENDITURE_OPTIONS} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={520} />
            <Tooltip title={analysisFormData['relative_advertising_expenditure_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['relative_advertising_expenditure_label']}:</InputLabelStyled>
            </Tooltip>
            <Dropdown name="relativeAdvertisingExpenditure" options={RELATIVE_EXPENDITURE_OPTIONS} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={520} />
            <Tooltip title={analysisFormData['relative_promotion_expenditure_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['relative_promotion_expenditure_label']}:</InputLabelStyled>
            </Tooltip>
            <Dropdown name="relativePromotionExpenditure" options={RELATIVE_EXPENDITURE_OPTIONS} />
          </FormRow>
        </FormSection>

        <FormSection>
          <FormSectionHeader variant="h2">{analysisFormData['market_characteristics']}</FormSectionHeader>

          <FormRow>
            <ChartModalTrigger chartId={596} />
            <Tooltip title={analysisFormData['approx_number_of_competitors_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['approx_number_of_competitors_label']}:</InputLabelStyled>
            </Tooltip>
            <Dropdown name="approxNumberOfCompetitors" options={NUMBER_OF_COMPETITORS} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={514} />
            <Tooltip title={analysisFormData['approx_share_of_second_competitor_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['approx_share_of_second_competitor_label']}:</InputLabelStyled>
            </Tooltip>
            <InputSlider name="approxShareOfSecond" min={0} max={49} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={613} />
            <Tooltip title={analysisFormData['approx_number_of_immediate_customers_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['approx_number_of_immediate_customers_label']}:</InputLabelStyled>
            </Tooltip>
            <Dropdown name="approxNumberOfImmediate" options={NUMBER_OF_CUSTOMERS} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={637} />
            <Tooltip title={analysisFormData['approx_number_of_end_users_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['approx_number_of_end_users_label']}:</InputLabelStyled>
            </Tooltip>
            <Dropdown name="approxNumberOfEndUsers" options={NUMBER_OF_CUSTOMERS} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={530} />
            <Tooltip title={analysisFormData['management_familiarity_tooltip']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['management_familiarity_label']}:</InputLabelStyled>
            </Tooltip>
            <Dropdown name="managementFamiliarity" options={FAMILIARITY} />
          </FormRow>

          <FormRow>
            <ChartModalTrigger chartId={531} />
            <Tooltip title={analysisFormData['percent_sales_immediate_customers_label']} arrow placement="right">
              <InputLabelStyled>{analysisFormData['percent_sales_immediate_customers_tooltip']}</InputLabelStyled>
            </Tooltip>
            <Dropdown name="percentSalesImmediateCustomers" options={SALES_PERCENTAGE} />
          </FormRow>
        </FormSection>
      </FormContainer>

      <FormFooter>
        <Button type="button" variant="outlined" onClick={onReset} endIcon={<RestartAltOutlined />}>
          {formElements['reset']}
        </Button>

        <Button type="submit" variant="contained" color="primary">
          {formElements['submit']}
        </Button>
      </FormFooter>
    </AnalysisFormWrapper>
  );
};
