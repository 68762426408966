import { numberToFixed } from '@helpers/formatNumber';

import { AnalysisDescriptionStyled } from './AnalysisDescription.styles';

interface AnalysisDescriptionProps {
  analysisData: any;
}
export const AnalysisDescription = ({ analysisData }: AnalysisDescriptionProps) => {
  const { likelyShare, likelyProbability, targetProbability } = analysisData;
  const likelyMarketShare = numberToFixed(likelyShare);
  const likelyMarketProbability = numberToFixed(likelyProbability);
  const targetMarketProbability = numberToFixed(targetProbability);

  const isDataReady = likelyMarketShare >= 0 && likelyMarketProbability && targetMarketProbability >= 0;

  if (!isDataReady) return null;

  return (
    <AnalysisDescriptionStyled>
      <p>Probability of achieving your target market share: {targetMarketProbability}%</p>
      <p>
        Likely market share ({likelyMarketProbability}% probability): {likelyMarketShare}%
      </p>
    </AnalysisDescriptionStyled>
  );
};
