import { Grid } from '@mui/material';
import { useMemo } from 'react';
import Plot from 'react-plotly.js';

import { getMultipleChartConfig, getMultipleChartData } from '@components/MultipleCharts/MultipleCharts.helpers';
import {
  ChartsContainerStyled,
  DescriptionBoxContainerStyled,
  DescriptionBoxDefinitionStyled,
  DescriptionBoxHeaderStyled,
  DescriptionBoxStyled,
  MultipleChartAnalysisBox,
  MultipleChartBox,
  MultipleChartContainerStyled,
  MultipleChartTypography,
} from '@components/MultipleCharts/MultipleCharts.styles';

import { StandardFindingsChartData } from '_types/Business';

interface MultipleChartWrapperProps {
  divId?: string;
  analysisData: StandardFindingsChartData;
}
export const MultipleChartWrapper = ({
  divId = 'multipleChart',
  analysisData,
  ...props
}: MultipleChartWrapperProps) => {
  const multipleChartConfig = useMemo(() => getMultipleChartConfig(analysisData), [analysisData]);

  const chartData = useMemo(() => getMultipleChartData(analysisData), [analysisData]);

  const isReady = multipleChartConfig && chartData;

  return (
    <>
      {isReady ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ChartsContainerStyled>
                <MultipleChartContainerStyled>
                  <MultipleChartAnalysisBox>
                    <MultipleChartBox>
                      <Plot
                        divId={divId}
                        data={chartData}
                        layout={multipleChartConfig}
                        config={{ responsive: true, displayModeBar: false }}
                        {...props}
                        useResizeHandler
                      />
                      <MultipleChartTypography variant="body2">
                        <span>
                          The data points show the average performance for businesses within the defined categories.
                        </span>
                      </MultipleChartTypography>
                    </MultipleChartBox>
                  </MultipleChartAnalysisBox>
                </MultipleChartContainerStyled>

                <DescriptionBoxContainerStyled>
                  <DescriptionBoxStyled>
                    <DescriptionBoxHeaderStyled>{analysisData.statement}</DescriptionBoxHeaderStyled>
                    <DescriptionBoxDefinitionStyled>{analysisData.rationale}</DescriptionBoxDefinitionStyled>

                    <DescriptionBoxHeaderStyled>Definition:</DescriptionBoxHeaderStyled>
                    <DescriptionBoxDefinitionStyled>{analysisData.definition}</DescriptionBoxDefinitionStyled>
                  </DescriptionBoxStyled>
                </DescriptionBoxContainerStyled>
              </ChartsContainerStyled>
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};
