import { StandardFindingsChartCategory, StandardFindingsChartOptions } from '_types/Chart';
import { Coordinates, CurveData, StartupShareAnalysisPayload } from '_types/Form';

export const initialCurveData: CurveData = [
  [3, 82.8],
  [5, 60.7],
  [7, 45.7],
  [9, 35.6],
  [11, 28.7],
  [13, 23.7],
  [15, 19.6],
  [17, 15.7],
  [19, 11.8],
  [21, 8.1],
  [23, 5],
  [25, 3.5],
  [27, 3.5],
];

export const initialCoordinates: Coordinates = {
  share: null,
  probability: null,
};

export const startupShareAnalysisPayload: StartupShareAnalysisPayload = {
  targetMarketShare: 0,
  proposedTotalMarketingSpend: 0,
  proposedSalesForceExpenditure: 0,
  productionCapacity: 0,
  relativeProductQuality: 0,
  relativeSalesforceExpenditure: null,
  relativeAdvertisingExpenditure: null,
  relativePromotionExpenditure: null,
  approxNumberOfCompetitors: null,
  approxShareOfSecond: 0,
  approxNumberOfImmediate: null,
  approxNumberOfEndUsers: null,
  managementFamiliarity: null,
  percentSalesImmediateCustomers: null,
};

export const initialChartOptions = {
  chart: {
    type: 'spline',
    inverted: false,
    height: '100%',
  },
  title: {
    text: 'Probability of achieving share',
  },
  subtitle: {
    text: '',
  },
  legend: {
    enabled: true,
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
  },
  xAxis: {
    min: 0,
    tickLength: 0,
    showFirstLabel: true,
    lineColor: '#000000',
    gridLineColor: '#6c757d',
    reversed: false,
    gridLineWidth: 1,
    title: {
      text: 'Market Share (%)',
    },
    labels: {
      format: '{value} ',
    },
    maxPadding: 0.05,
    showLastLabel: true,
    lineWidth: 1,
  },
  yAxis: {
    min: 0,
    lineColor: '#000000',
    gridLineColor: '#6c757d',
    gridLineWidth: 1,
    title: {
      text: 'Probability (%)',
    },
    labels: {
      format: '{value}',
    },
    lineWidth: 1,
  },
  tooltip: {
    headerFormat: '<b>{series.name}</b><br/>',
    pointFormat: '{point.x} Probability %: {point.y} Market Share %',
    style: {
      fontSize: '12px',
    },
  },
  plotOptions: {
    spline: {
      lineWidth: 4,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      marker: {
        enabled: false,
      },
    },
  },
  series: [
    {
      type: 'spline',
      name: 'Market Share',
      data: initialCurveData,
      color: '#284D99',
      lineWidth: 2,
    },
  ],
};

export const standardFindingsChartCategory: StandardFindingsChartCategory[] = [
  { id: 1, name: 'Start-Up Profile' },
  { id: 2, name: 'Market Characteristics' },
  { id: 3, name: 'Post Entry Factors' },
];

export const standardFindingsChartOptions: StandardFindingsChartOptions[] = [
  'Start-Up Profile',
  { chartId: 1, categoryId: 1, description: 'Marketing intensity', vbl: 572 },
  { chartId: 2, categoryId: 1, description: 'Sales force intensity', vbl: 467 },
  { chartId: 3, categoryId: 1, description: 'Capacity', vbl: 575 },
  { chartId: 4, categoryId: 1, description: 'Relative quality', vbl: 573 },
  { chartId: 5, categoryId: 1, description: 'Relative marketing', vbl: 520 },
  'Market Characteristics',

  { chartId: 6, categoryId: 2, description: 'Competitor concentration', vbl: 596 },
  { chartId: 7, categoryId: 2, description: 'Share competitor B', vbl: 514 },
  { chartId: 8, categoryId: 2, description: 'Customer concentration', vbl: 613 },
  { chartId: 9, categoryId: 2, description: 'End user concentration', vbl: 637 },
  { chartId: 10, categoryId: 2, description: 'Managers market familiarity', vbl: 530 },
  { chartId: 11, categoryId: 2, description: 'Managers customer familiarity', vbl: 531 },

  'Post Entry Factors',
  { chartId: 12, categoryId: 3, description: 'Share competitor A', vbl: 102 },
  { chartId: 13, categoryId: 3, description: 'Relative value', vbl: 609 },
  { chartId: 14, categoryId: 3, description: 'Relative price', vbl: 574 },
  { chartId: 15, categoryId: 3, description: 'Second wave innovation pays', vbl: 509 },
  { chartId: 16, categoryId: 3, description: 'Advertising & promotion intensity', vbl: 471 },
  { chartId: 17, categoryId: 3, description: 'Market growth', vbl: 508 },
  { chartId: 18, categoryId: 3, description: 'Product breadth', vbl: 631 },
];

export const DECIMAL_POINTS = 1;

export const ROUNDING_PRECISION = 10;
