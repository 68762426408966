import { CssBaseline, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { SIDEBAR_ITEMS } from '@constants/sidebar';
import { useTranslator } from '@hooks/translator';

import pimsLogo from '@assets/images/pims-ai-logo-white.svg';

import { AppSidebarHeaderStyled, AppSidebarStyled, NavLinkStyled } from './Sidebar.styles';

export const Sidebar = () => {
  const location = useLocation().pathname;

  const [sidebarData] = useTranslator(undefined, ['sidebar']);

  return (
    <AppSidebarStyled>
      <AppSidebarHeaderStyled>
        <img className="app-logo" src={pimsLogo} alt="PIMS logo" />
      </AppSidebarHeaderStyled>
      <List>
        <CssBaseline />
        {SIDEBAR_ITEMS.map((item) => {
          const color = location === item.url ? '#fff' : '#708fc5';
          const Icon = item.icon;

          return (
            <ListItem key={item.key} disablePadding sx={{ fontSize: '12px', marginBottom: '1px', height: '45px' }}>
              <NavLinkStyled to={item.url}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <Icon sx={{ color }} />
                </ListItemIcon>
                <ListItemText primary={sidebarData[item.key]} sx={{ color }} />
              </NavLinkStyled>
            </ListItem>
          );
        })}
      </List>
    </AppSidebarStyled>
  );
};
