import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AnalysisDescriptionStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 10% 0;
  padding: 20px 40px;
  font-size: 15px;
  text-align: right;
  line-height: 1;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 3px 6px #1919190f;
`;
