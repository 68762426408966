import { Box, Grid, Modal } from '@mui/material';
import { useState } from 'react';

import { MultipleChartWrapper } from '@components/MultipleCharts/MultipleChartWrapper.component';
import { Spinner } from '@components/Spinner';
import { ChartIconWrapper, ModalStyles } from '@features/Analysis/ChartModalTrigger.styles';
import { businessService } from '@services/api/business.service';

import { ReactComponent as ChartIcon } from '@assets/icons/chart_trigger.svg';

import { StandardFindingsChartData } from '_types/Business';

const DECIMAL_POINTS = 1;

interface ChartModalProps {
  chartId: number;
}

export const ChartModalTrigger = ({ chartId = 572 }: ChartModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [selectedChart, setSelectedChart] = useState<number>(chartId);
  const [standardFindingsData, setStandardFindingsData] = useState<StandardFindingsChartData>({});

  const handleOpenModal = () => {
    setIsOpen(true);
    const payload = {
      vbl: selectedChart,
      lang: 44,
    };

    businessService.getStandardFindingsChart(payload).then((response) => {
      response.sharePoints = response.sharePoints?.map((point) => {
        return Number.parseFloat(point.toFixed(DECIMAL_POINTS));
      });

      setStandardFindingsData(response);
      setIsReady(true);
    });

    setSelectedChart(chartId);
  };

  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <ChartIconWrapper onClick={handleOpenModal}>
        <ChartIcon />
      </ChartIconWrapper>
      <Modal open={isOpen} onClose={handleCloseModal} disableEnforceFocus>
        <Box sx={ModalStyles}>
          {isReady ? (
            <Grid item xs={12}>
              <MultipleChartWrapper analysisData={standardFindingsData} />
            </Grid>
          ) : (
            <Spinner />
          )}
        </Box>
      </Modal>
    </>
  );
};
