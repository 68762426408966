import axios, { AxiosInstance } from 'axios';

import { LogService } from './log.service';

export const API_URL = process.env.REACT_APP_API_URL || 'https://portal.pims.ai/pimshub/api/';

class HttpService extends LogService {
  public http: AxiosInstance;

  constructor() {
    super();

    this.http = axios.create({
      baseURL: API_URL,

      headers: {
        AccessControlAllowOrigin: `${API_URL}*`,
      },
    });
  }

  create = async <T>(url: string, payload: T) => {
    try {
      const { data } = await this.http.post(url, payload);

      return data;
    } catch (error) {
      this.error(error);

      return Promise.reject(error);
    }
  };
}

export const httpService = new HttpService();
