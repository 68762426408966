import { ContentCopy } from '@mui/icons-material';
import { Grid, IconButton, ListSubheader, Select } from '@mui/material';
import Plotly from 'plotly.js-dist-min';
import { useEffect, useMemo } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';

import { standardFindingsChartOptions } from '@constants/chart';
import { toPascalCase } from '@helpers/formatString';

import { StandardFindingsChartData } from '_types/Business';

import { getMultipleChartConfig, getMultipleChartData } from './MultipleCharts.helpers';
import {
  ActionIcons,
  ChartHeaderStyled,
  ChartSelectStyles,
  ChartsContainerStyled,
  DescriptionBoxContainerStyled,
  DescriptionBoxDefinitionStyled,
  DescriptionBoxHeaderStyled,
  DescriptionBoxStyled,
  MenuItemStyled,
  MultipleChartAnalysisBox,
  MultipleChartBox,
  MultipleChartContainerStyled,
  MultipleChartTypography,
} from './MultipleCharts.styles';

const Plot = createPlotlyComponent(Plotly);

interface MultipleChartProps {
  analysisData: StandardFindingsChartData;
  chartId: number;
  onSelect: any;
}

const MultipleChartContainerStyles = {
  display: 'flex',
  flex: 1,
  width: '50%',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const MultipleChart = ({ analysisData, chartId, onSelect }: MultipleChartProps) => {
  const multipleChartConfig = useMemo(() => getMultipleChartConfig(analysisData), [analysisData]);

  const chartData = useMemo(() => getMultipleChartData(analysisData), [analysisData]);

  const isDataReady = multipleChartConfig && chartData;

  useEffect(() => {
    const handleResize = () => {
      Plotly.Plots.resize('multipleChart');
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const downloadChartAsImage = () => {
    if (isDataReady) {
      const { margin, xaxis, yaxis } = multipleChartConfig;

      multipleChartConfig.font = {
        family: '"Roboto", helvetica, arial, sans-serif',
        size: 16,
      };
      multipleChartConfig.xaxis = {
        ...xaxis,
        title: {
          ...xaxis.title,
          font: {
            size: 14,
            color: 'black',
          },
        },
      };
      multipleChartConfig.yaxis = {
        ...yaxis,
        title: {
          ...yaxis.title,
          font: {
            size: 14,
            color: 'black',
          },
        },
      };

      multipleChartConfig.margin = { ...margin, t: 50, b: 50 };

      const filename = toPascalCase(analysisData.title);

      Plotly.downloadImage('multipleChart', {
        format: 'png',
        filename,
        width: 1600,
        height: 1200,
      });
    }
  };

  return (
    <>
      {isDataReady ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ChartsContainerStyled>
              <MultipleChartContainerStyled>
                <ChartHeaderStyled>
                  <Select id="chart-type-select" value={chartId} onChange={onSelect} sx={ChartSelectStyles}>
                    {standardFindingsChartOptions.map((option) => {
                      const hasSubheadings =
                        standardFindingsChartOptions && typeof standardFindingsChartOptions[0] === 'string';

                      if (typeof option === 'string') {
                        return (
                          <ListSubheader key={option} sx={{ fontWeight: 'bold', height: '40px' }}>
                            {option}
                          </ListSubheader>
                        );
                      } else {
                        return (
                          <MenuItemStyled key={option.chartId} value={option.vbl} dense hasSubheadings={hasSubheadings}>
                            {option.description}
                          </MenuItemStyled>
                        );
                      }
                    })}
                  </Select>
                  <ActionIcons>
                    <IconButton onClick={downloadChartAsImage}>
                      <ContentCopy sx={{ color: '#0047AB', fontSize: '20px' }} />
                    </IconButton>
                  </ActionIcons>
                </ChartHeaderStyled>
                <MultipleChartAnalysisBox>
                  <MultipleChartBox>
                    <Plot
                      divId="multipleChart"
                      data={chartData}
                      layout={multipleChartConfig}
                      config={{
                        responsive: true,
                        displayModeBar: false,
                      }}
                      useResizeHandler
                    />
                    <MultipleChartTypography variant="body2">
                      <span>
                        The data points show the average performance for businesses within the defined categories.
                      </span>
                    </MultipleChartTypography>
                  </MultipleChartBox>
                </MultipleChartAnalysisBox>
              </MultipleChartContainerStyled>

              <DescriptionBoxContainerStyled>
                <DescriptionBoxStyled>
                  <DescriptionBoxHeaderStyled>{analysisData.statement}</DescriptionBoxHeaderStyled>
                  <DescriptionBoxDefinitionStyled>{analysisData.rationale}</DescriptionBoxDefinitionStyled>
                  <DescriptionBoxHeaderStyled>Definition:</DescriptionBoxHeaderStyled>
                  <DescriptionBoxDefinitionStyled>{analysisData.definition}</DescriptionBoxDefinitionStyled>
                </DescriptionBoxStyled>
              </DescriptionBoxContainerStyled>
            </ChartsContainerStyled>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
