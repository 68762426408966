import { DescriptionOutlined, HomeOutlined, TimelineOutlined } from '@mui/icons-material';
import { ReactElement } from 'react';

import { Analysis } from '@features/Analysis';
import { HomePage } from '@features/HomePage';
import { Research } from '@features/Research';

export type SidebarItem = {
  name: string;
  key: string;
  url: string;
  element: () => ReactElement;
  icon: any;
};

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    name: 'Home',
    key: 'home',
    url: '/',
    element: HomePage,
    icon: HomeOutlined,
  },
  {
    name: 'Analysis',
    key: 'analysis',
    url: '/analysis',
    element: Analysis,
    icon: DescriptionOutlined,
  },
  {
    name: 'Research',
    key: 'research',
    url: '/research',
    element: Research,
    icon: TimelineOutlined,
  },
];
